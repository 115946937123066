import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const ProximosEventos = () => (
	<Layout>

		<SEO title="Grácias" />

		<section className="gracias container text-center">
			<svg width="230" height="230" viewBox="0 0 230 230" xmlns="http://www.w3.org/2000/svg"><title>Grácias</title><g fill="none" fillRule="evenodd"><circle fill="#F6F6F6" cx="114.559" cy="114.559" r="114.559"/><path d="M173.964 81.403l-55.024 35.06c-2.64 1.69-6.017 1.64-8.605-.122L55.11 78.806v-.382c0-3.86 3.14-6.998 7-6.998h104.856c3.86 0 6.998 3.14 6.998 6.998v2.98zm0 65.238l-35.393-35.386L173.965 88.7v57.94zm-6.998 11.37H62.11c-2.568 0-4.903-1.398-6.128-3.615l40.21-40.22 10.684 7.256c2.327 1.582 5.043 2.417 7.854 2.417 2.675 0 5.277-.758 7.522-2.193l11.053-7.044 39.785 39.783c-1.233 2.218-3.566 3.616-6.124 3.616zM55.11 86.25l35.953 24.434L55.11 146.64V86.25zm111.856-20.99H62.11c-7.26 0-13.165 5.905-13.165 13.163v72.588c0 7.253 5.905 13.152 13.164 13.152h104.856c7.26 0 13.164-5.9 13.164-13.15V78.42c0-7.257-5.905-13.163-13.164-13.163z" fill="#616161"/><g transform="translate(141.16 124.844)"><circle fill="#FFF" cx="26.6" cy="26.6" r="26.6"/><path d="M26.456 0C11.846 0 0 11.846 0 26.456S11.846 52.91 26.456 52.91 52.91 41.066 52.91 26.457C52.91 11.846 41.066 0 26.457 0zm13.57 17.61l-16.43 21.08c-.128.13-.34.415-.6.415-.273 0-.45-.19-.603-.343-.154-.153-9.32-8.964-9.32-8.964l-.176-.177c-.07-.106-.13-.235-.13-.377 0-.142.06-.272.13-.378.047-.047.083-.083.13-.142.91-.956 2.752-2.893 2.87-3.01.154-.155.283-.356.567-.356.295 0 .484.248.626.39.142.142 5.315 5.114 5.315 5.114L35.55 13.973c.118-.095.26-.165.413-.165.154 0 .295.06.413.153l3.614 2.846c.095.118.154.26.154.414.012.153-.047.283-.118.39z" fill="#7ED321" fillRule="nonzero"/></g></g></svg>
			<h1>Gracias, tu mensaje ha sido enviado.</h1>
			<p>Un representante se pondrá en contacto contigo.</p>
		</section>

	</Layout>
)

export default ProximosEventos
